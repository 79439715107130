<template>
  <div>
    <el-dialog title :visible.sync="dialogVisible" width="62%" custom-class="dialog"
      @close="resetForm">
      <div class="content">
        <h2 class="title">
          <div class="title_icon"></div>查看通道
        </h2>

        <el-form :inline="true" ref="form" label-width="140px" style="padding:0">
          <el-form-item label="通道名称：">
            <el-input v-model="form.parkChannelName" :disabled="true" style="200px"
              placeholder="请输入通道名称"></el-input>
          </el-form-item>
          <el-form-item label="通道编码：">
            <el-input v-model="form.parkChannelCode" :disabled="true" style="width:300px;"
              placeholder="请输入通道编码"></el-input>
          </el-form-item>
          <el-form-item label="出入口类型：">
            <el-select v-model.trim="form.channelType" placeholder="请选择" style="200px"
              :disabled="true">
              <!--								<el-option :value="1" label="外场入口">外场入口</el-option>
								<el-option :value="2" label="外场出口">外场出口</el-option>
								<el-option :value="3" label="内场入口">内场入口</el-option>
              <el-option :value="4" label="内场出口">内场出口</el-option>-->

              <el-option :label="value.desc" :value="value.code" v-for="value in channelList"
                :key="value.code"></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="是否直连场外" prop="directConnect">
            <el-radio-group v-model="form.directConnect" :disabled="true">
              <el-radio :label="1" value=1>是</el-radio>
              <el-radio :label="0" value=0>否</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>

        <h2 class="title">
          <div class="title_icon"></div>管理设备
        </h2>

        <!-- <el-tabs v-model="activeName2" type="card">
          <el-tab-pane label="设备管理" name="first"> -->
        <el-table header-cell-class-name="header-call-style" v-loading="loading"
          :data="tableData" style="width: 100%;">
          <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>

          <el-table-column :prop="item.prop" :label="item.label" :width="item.width"
            v-for="item in tableCols" :key="item.prop" :formatter="item.formatter" align="center">
          </el-table-column>
          <el-table-column :label="details.parkSystemType == 0 ? '设备状态' : '状态'" align='center'>
            <template slot-scope="scope">
              <div v-if="details.parkSystemType == 0" :style="{color: scope.row.equipActualState != '离线' ? 'green':'red'}">
                {{scope.row.equipActualState}}</div>
              <span v-else
                    :class="scope.row.equipmentState | colorFilter"
                  >{{ scope.row.equipmentState | stateFilter}}</span>
            </template>
          </el-table-column>
        </el-table>
        <!-- </el-tab-pane> -->
        <!-- <el-tab-pane label="视频监控点" name="second">
            <el-table
              header-cell-class-name="header-call-style"
              border
              v-loading="loading"

              :data="tableData"
              style="width: 100%;"
            >
              <el-table-column type="index" :label="$t('list.index')" width="70" align="center"></el-table-column>

              <el-table-column
                :prop="item.prop"
                :label="item.label"
                :width="item.width"
                v-for="item in tableCols2"
                :key="item.prop"
                :formatter="item.formatter"
                align="center"
              ></el-table-column>
              <el-table-column :label="$t('searchModule.state')" width align="center">
                <template slot-scope="scope">
                  <span
                    :class="scope.row.equipmentState | colorFilter"
                  >{{ scope.row.equipmentState | stateFilter}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs> -->
      </div>
      <div slot="footer">
        <el-button @click="resetForm">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  export default {
    filters: {
      stateFilter(state) {
        return state == 1 ? "在线" : state == 2 ? "未知" : "离线";
      },
      colorFilter(state) {
        return state == 1 ? "color_1" : "color_2";
      },
    },
    props: ['details'],
    data() {
      return {
        activeName2: "first",
        parkChannelId: "",
        dialogVisible: false,
        loading: false,
        tableData: [],
        // tableData2: [],
        tableCols: [{
            prop: 'equipmentName',
            label: '设备名称'
          },
          {
            prop: "equipmentType",
            label: this.$t("list.Equipment_type"),
            width: "",
            formatter: (row) => {
              switch (row.equipmentType) {
                case 1:
                  return "MS设备";
                case 2:
                  return "闸机";
                case 3:
                  return "LED屏";
                case 4:
                  return "识别一体机";
                case 5:
                  return "IP对讲";
                case 6:
                  return "LED缴费屏";
                case 13:
                  return "智能控制板";
                case 14:
                  return "车道感知机器人";
                default:
              }
            }
          },
          // {
          //   prop: 'equipmentState',
          //   label: '运营状态',
          //   width: '',
          //   formatter: (row, column, cellValue, index) => {
          //     return cellValue == 1 ? "使用" : '停用';
          //   }
          // },
          {
            prop: "ipAddress",
            label: this.$t("list.IP_address"),
            width: "",
          },
        ],
        tableCols2: [{
            prop: "equipmentType",
            label: "视频监控点名称",
            width: "",
          },
          {
            prop: "ipAddress",
            label: "视频监控点编码",
            width: "",
          },
          {
            prop: "ipAddress",
            label: "运营状态",
            width: "",
          },
          {
            prop: "ipAddress",
            label: "设备状态",
            width: "",
          },
        ],
        form: {
          parkChannelName: "",
          parkChannelCode: "",
          channelType: "",
          directConnect: "",
        },
        channelList: [],
      };
    },
    methods: {
      // 打开窗口内容
      open() {
        this.dialogVisible = true;
        this.form.parkChannelName = "";
        this.form.parkChannelCode = "";
        this.form.channelType = "";
        this.form.directConnect = "";
      },

      // 获取详情
      getDetail(id) {
        // console.log("对应ID:" + id);
        this.parkChannelId = id;
        this.getChannelData();
        if (this.details.parkSystemType == 0) {
          this.getFormInfo();
        } else {
          this.getEquipoData();
        }

        // this.getEquipoData2();
      },

      // 获取详情
      getFormInfo() {
        this.$axios
          .get("/acb/2.0/bacb/parkChannel/getById", {
            data: {
              parkChannelId: this.parkChannelId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.form.parkChannelName = res.value.parkChannel.parkChannelName;
              this.form.parkChannelCode = res.value.parkChannel.parkChannelCode;
              this.form.channelType = res.value.parkChannel.channelType;
              this.form.directConnect = res.value.parkChannel.directConnect;
              this.tableData = res.value.equipmentList || [];
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      },
      // 获取出入口类型
      getChannelData() {
        this.$axios
          .get("/acb/2.0/bacb/common/getOptions", {
            data: {},
          })
          .then((res) => {
            if (res.state == 0) {
              // if (res.value) {

              this.channelList = res.value.parkChannelType;

              // }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      },
      // 非ai设备
      getEquipoData() {
        this.$axios
          .get("/acb/2.0/bacb/parkChannel/getEquipmentsById", {
            data: {
              parkChannelId: this.parkChannelId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              // if (res.value) {
              this.tableData = res.value || [];

              // }
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      },

      // getEquipoData2() {
      //   this.$axios
      //     .get("/acb/2.0/bacb/parkChannel/getEquipmentsById", {
      //       data: {
      //         parkChannelId: this.parkChannelId,
      //       },
      //     })
      //     .then((res) => {
      //       if (res.state == 0) {
      //         // if (res.value) {
      //         this.tableData2 = res.value || [];

      //         // }
      //       } else {
      //         this.$alert(res.desc, this.$t('pop_up.Tips'), {
      //           confirmButtonText: this.$t('pop_up.Determine'),
      //         });
      //       }
      //     });
      // },

      // 取消
      resetForm() {
        this.dialogVisible = false;
        this.$refs.form.resetFields();
      }
    },
    mounted() {
      if (this.details.parkSystemType != 0) {
        this.tableCols.splice(0, 1)
      } else if (this.details.parkSystemType == 0) {
        this.tableCols.splice(2, 1)
      }
    }
  }

</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
  .color_1 {
    color: #333333;
  }

  .color_2 {
    color: #D9001B;
  }

</style>
<style>
  .formWidth {
    width: 221.5px;
  }

</style>

<template>
  <div>
    <el-dialog
      title
      :visible.sync="dialogVisible"
      width="62%"
      custom-class="dialog"
      @close="resetForm"
    >
      <div class="content">
        <h2 class="title">
          <div class="title_icon"></div>
          {{ type == "edit" ? "编辑" : "添加" }}通道
        </h2>
        <el-form
          :inline="true"
          ref="form"
          :rules="rules"
          label-width="140px"
          :model="form"
          style="padding: 0"
        >
          <!-- :disabled="true" -->
          <el-form-item label="通道名称：" prop="parkChannelName">
            <el-input
              v-model="form.parkChannelName"
              style="200px"
              placeholder="请输入通道名称"
            ></el-input>
          </el-form-item>
          <!---->
          <el-form-item label="通道编码：" prop="parkChannelCode" v-if="type == 'edit'">
            <el-input
              v-model="form.parkChannelCode"
              :disabled="true"
              style="200px"
              placeholder="请输入通道编码"
            ></el-input>
          </el-form-item>
          <!-- :disabled="true" -->
          <el-form-item label="出入口类型：" prop="channelType">
            <el-select v-model.trim="form.channelType" placeholder="请选择" style="200px">
              <el-option
                v-show="value.code === 1 || value.code === 2"
                :label="value.desc"
                :value="value.code"
                v-for="value in channelList"
                :key="value.code"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- :disabled="true" -->
          <!-- <el-form-item label="是否直连场外" prop="directConnect">
            <el-radio-group v-model="form.directConnect">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>

        <h2 class="title">
          <div class="title_icon"></div>
          关联AI设备
        </h2>
        <!-- <el-form-item class="parkc" :label="$t('searchModule.Choose_parking_lot')" style="width:700px;" prop="parkIds"> -->
        <el-transfer
          target-order="push"
          :titles="['未选择', '已选择']"
          filterable
          :props="{
            key: 'equipmentId',
            label: 'equipmentName',
          }"
          filter-placeholder="请输入AI设备名称"
          v-model="selected"
          :data="equipmentList"
        >
        </el-transfer>
        <!-- </el-form-item> -->
      </div>
      <div slot="footer">
        <el-button type="primary" @click="submitData">{{ $t('button.preservation') }}</el-button>
        <el-button @click="resetForm">返回</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  filters: {
    stateFilter(state) {
      return state == 1 ? "在线" : "离线";
    },
    colorFilter(state) {
      return state == 1 ? "color_1" : "color_2";
    },
  },
  props: {
    parkId: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data() {
    return {
      activeName2: "first",
      parkChannelId: "",
      dialogVisible: false,
      loading: false,
      selected: [],
      equipmentList: [],
      form: {
        parkChannelName: "",
        parkChannelCode: "",
        channelType: "",
        // directConnect: 1,
      },
      rules: {
        parkChannelName: [
          {
            required: true,
            message: "通道名称必填",
            trigger: "blur",
          },
        ],
        parkChannelCode: [
          {
            required: true,
            message: "通道编码必填",
            trigger: "blur",
          },
        ],
        channelType: [
          {
            required: true,
            message: "出入口类型必填",
            trigger: "blur",
          },
        ],
        // directConnect: [{
        //   required: true,
        //   message: '是否直连场外必填',
        //   trigger: 'blur'
        // }]
      },
      channelList: [],
      type: "",
    };
  },
  methods: {
    // 打开窗口内容
    open(e) {
      this.dialogVisible = true;
      this.type = e;
      if (this.type == "add") {
        this.parkChannelId = "";
      }
      this.form.parkChannelName = "";
      this.form.parkChannelCode = "";
      this.form.channelType = "";
      // this.form.directConnect = 1;
      this.getChannelData();
      this.getAIEquipment();
    },
    // 获取详情
    getDetail(id) {
      // console.log("对应ID:" + id);
      this.parkChannelId = id;
      this.getFormInfo();
    },
    // 获取详情
    getFormInfo() {
      this.$axios
        .get("/acb/2.0/bacb/parkChannel/getById", {
          data: {
            parkChannelId: this.parkChannelId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.form.parkChannelName = res.value.parkChannel.parkChannelName;
            this.form.parkChannelCode = res.value.parkChannel.parkChannelCode;
            this.form.channelType = res.value.parkChannel.channelType;
            // this.form.directConnect = res.value.parkChannel.directConnect;
            this.selected = res.value.equipmentList.map((res) => {
              return res.equipmentId;
            });
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 获取出入口类型
    getChannelData() {
      this.$axios
        .get("/acb/2.0/bacb/common/getOptions", {
          data: {},
        })
        .then((res) => {
          if (res.state == 0) {
            // if (res.value) {
            this.channelList = res.value.parkChannelType;
            // }
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 查询设备列表
    getAIEquipment() {
      let url = "/acb/2.0/bacb/parkChannel/getAIEquipment";
      this.$axios
        .get(url, {
          data: {
            pageNum: 0,
            pageSize: 0,
            parkId: this.parkId,
            parkChannelId: this.parkChannelId,
          },
        })
        .then((res) => {
          if (res.state != 0) {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.equipmentList = res.value.list;
          // console.log(res);
        });
    },
    // 取消
    resetForm() {
      this.dialogVisible = false;
      this.$refs.form.resetFields();
    },
    submitData() {
      if (this.selected.length > 1) {
        this.$alert("AI设备只能选择一个", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return false;
      }
      this.$refs.form.validate((valid) => {
        // console.log(valid)
        if (valid) {
          // /acb/2.0/bacb/parkChannel/add || /acb/2.0/bacb/parkChannel/update
          let url =
            this.type == "edit"
              ? "/acb/2.0/bacb/parkChannel/update"
              : "/acb/2.0/bacb/parkChannel/add";
          let data =
            this.type == "edit"
              ? {
                  parkChannelId: this.parkChannelId,
                  parkId: this.parkId,
                  equipments: this.selected.join(),
                  ...this.form,
                }
              : {
                  parkId: this.parkId,
                  equipments: this.selected.join(),
                  ...this.form,
                };
          this.$axios
            .post(url, {
              data: data,
            })
            .then((res) => {
              if (res.state != 0) {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                return false;
              } else {
                this.dialogVisible = false;
                this.$alert("操作成功", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine'),
                });
                this.$emit("searchData");
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.color_1 {
  color: #333333;
}

.color_2 {
  color: #D9001B;
}
</style>
<style>
.formWidth {
  width: 221.5px;
}


</style>
